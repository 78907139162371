import { container, title } from "assets/jss/material-kit-react.jsx";

const landingPageStyle = {
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0"
  },
  main: {
    background: "linear-gradient(0deg, #ffffff 0%, #ffffff 70%, #e9ecef 80%)",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
      
      "@media (max-width: 600px)": {
        margin: "-90px 16px 0px",
      },
    },
  brand: {
    color: "#FFFFFF",
    textAlign: "center",
    paddingBottom: "3.5rem",
    marginTop: "-14rem",
    "@media (max-width: 600px)": {
      color: "#FFFFFF",
      textAlign: "center",
      paddingBottom: "3.5rem",
      marginTop: "-20rem",
    },

  },
  intro: {
    wordSpacing: "0.2rem",
    fontSize: "1.5rem",
    fontWeight: "200",
    textAlign: "center",
    textTransform: "uppercase",
    marginTop: "2rem",
    marginBottom: "0.25rem",
    "@media (max-width: 600px)": {
      wordSpacing: "0.2rem",
      fontSize: "1rem",
      fontWeight: "200",
      textAlign: "center",
      textTransform: "uppercase",
      marginTop: "2rem",
      marginBottom: "0.25rem",
    },
  },
  title: {
    marginTop: "-2px",
    fontSize: "4.25rem",
    fontWeight: "900",
    display: "inline-block",
    position: "relative",
    letterSpacing: "0.02em",
    textTransform: "uppercase",
    WebkitTextStroke: "1px white",
    color: "rgba(0, 0, 0 , 0.1)",
    "@media (max-width: 600px)": {
      marginTop: "-2px",
      fontSize: "3rem",
      fontWeight: "900",
      display: "inline-block",
      position: "relative",
      letterSpacing: "0.02em",
      textTransform: "uppercase",
      WebkitTextStroke: "1px white",
      color: "rgba(0, 0, 0 , 0.1)",
    },
  },
  iconarea: {
    marginTop: "-34rem",
    height: "auto",
    position: "relative",
    backdropFilter: "blur(2px)",
    "@media (max-width: 600px)": {
      marginTop: "-40rem",
      height: "auto",
      position: "relative",
      backdropFilter: "blur(2px)",
    },
  },
  border: {
  },
  locationstext: {
    color: "#000103",
    fontSize: "14px",
    fontWeight: "300",
    textAlign: "center",
    padding: "6rem 8rem",
    opacity: "0.8",
    background: "radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(233,236,239,1) 70%, rgba(233,236,239,1) 100%)",
    "@media (max-width: 1000px)": {
      color: "#000103",
      fontSize: "12px",
      fontWeight: "300",
      textAlign: "center",
      padding: "5rem 4rem",
      opacity: "0.8",
    },
    "@media (max-width: 600px)": {
      color: "#000103",
      fontSize: "12px",
      fontWeight: "300",
      textAlign: "center",
      padding: "4rem 1rem",
      opacity: "0.8",
    },
  },
  homeimage: {
    maxWidth: "100%",
    maxHeight: "100%",
    overflow: "hidden",
  },
  imagewrap: {
    width: "100%",
    height: "auto",
  },
  headinginverse: {
    marginTop: "-2px",
    fontSize: "3.4rem",
    fontWeight: "800",
    display: "inline-block",
    position: "relative",
    letterSpacing: "0.02em",
    textTransform: "uppercase",
    color: "#0b292c",
    marginBottom: "4rem",
    marginLeft: "25%",
    "@media (max-width: 1300px)": {
      marginTop: "-2px",
      fontSize: "2rem",
      fontWeight: "800",
      display: "inline-block",
      position: "relative",
      letterSpacing: "0.02em",
      textTransform: "uppercase",
      color: "#0b292c",
      marginBottom: "4rem",
      marginLeft: "7%",
    },
    "@media (max-width: 900px)": {
      marginTop: "-2px",
      fontSize: "2rem",
      fontWeight: "800",
      display: "inline-block",
      position: "relative",
      letterSpacing: "0.02em",
      textTransform: "uppercase",
      color: "#0b292c",
      marginBottom: "4rem",
      marginLeft: "5%",
    },
    "@media (max-width: 600px)": {
      marginTop: "-2px",
      fontSize: "2rem",
      fontWeight: "800",
      display: "inline-block",
      position: "relative",
      letterSpacing: "0.02em",
      textTransform: "uppercase",
      color: "#0b292c",
      marginBottom: "4rem",
      marginLeft: "auto",
      width: "100%",
      textAlign: "center",
    }
  },
  preheadinginverse: {
    fontSize: "1.5rem",
    textTransform: "uppercase",
    color: "#0b292c",
    opacity: "0.9",
    marginTop: "10%",
    marginBottom: "5px",
    marginLeft: "25.5%",
    "@media (max-width: 1300px)": {
      fontSize: "1.5rem",
      textTransform: "uppercase",
      color: "#0b292c",
      opacity: "0.9",
      marginTop: "120px",
      marginBottom: "5px",
      marginLeft: "7.5%",
    },
    "@media (max-width: 900px)": {
      fontSize: "1.5rem",
      textTransform: "uppercase",
      color: "#0b292c",
      opacity: "0.9",
      marginTop: "120px",
      marginBottom: "5px",
      marginLeft: "5%",
    },
    "@media (max-width: 600px)": {
      fontSize: "1.1rem",
      textTransform: "uppercase",
      color: "#0b292c",
      opacity: "0.9",
      marginTop: "20px",
      marginBottom: "5px",
      marginLeft: "auto",
      width: "100%",
      textAlign: "center",
    }
  },
  parawrapinverse: {
    position: "relative",
    marginLeft: "25%",
    marginRight: "40px",
    "@media (max-width: 1300px)": {
      position: "relative",
      marginLeft: "7%",
      marginRight: "40px",
    },
    "@media (max-width: 900px)": {
      position: "relative",
      marginLeft: "6%",
      marginRight: "40px",
    },
    "@media (max-width: 600px)": {
      position: "relative",
      marginLeft: "20px",
      marginRight: "20px",
    }
  },
  paratextinverse: {
    fontSize: "14px",
    marginBottom: "1.2rem",
    "@media (max-width: 1300px)": {
      fontSize: "14px",
      marginBottom: "1.2rem",
    },
    "@media (max-width: 600px)": {
      fontSize: "12px",
      marginBottom: "1.2rem",
      textAlign: "center",
    } 
  },
  paddingone: {
    paddingBottom: "5rem",
  },
  paddingonebottom: {
    paddingBottom: "10rem",
    "@media (max-width: 600px)": {
      paddingBottom: "5rem",
    } 
  },
  mainwrap: {
    margin: "auto",
    maxWidth: "2100px",
  }, 
};

export default landingPageStyle;

// headinginverse: {
//   marginTop: "-2px",
//   fontSize: "3.4rem",
//   fontWeight: "800",
//   display: "inline-block",
//   position: "relative",
//   letterSpacing: "0.02em",
//   textTransform: "uppercase",
//   color: "#0b292c",
//   marginBottom: "4rem",
//   marginLeft: "40%",
//   "@media (max-width: 1300px)": {
//     marginTop: "-2px",
//     fontSize: "2rem",
//     fontWeight: "800",
//     display: "inline-block",
//     position: "relative",
//     letterSpacing: "0.02em",
//     textTransform: "uppercase",
//     color: "#0b292c",
//     marginBottom: "4rem",
//     marginLeft: "7%",
//   }
// },
// preheadinginverse: {
//   fontSize: "1.5rem",
//   textTransform: "uppercase",
//   color: "#0b292c",
//   opacity: "0.9",
//   marginTop: "10%",
//   marginBottom: "5px",
//   marginLeft: "40.5%",
//   "@media (max-width: 1300px)": {
//     fontSize: "1.5rem",
//     textTransform: "uppercase",
//     color: "#0b292c",
//     opacity: "0.9",
//     marginTop: "120px",
//     marginBottom: "5px",
//     marginLeft: "7.5%",
//   }
// },
// parawrapinverse: {
//   position: "relative",
//   marginLeft: "40%",
//   marginRight: "40px",
//   "@media (max-width: 1300px)": {
//     position: "relative",
//     marginLeft: "7%",
//     marginRight: "40px",
//   }
// },
// paratextinverse: {
//   fontSize: "16px",
//   marginBottom: "1.2rem",
//   "@media (max-width: 1300px)": {
//     fontSize: "14px",
//     marginBottom: "1.2rem",
//   }
// },