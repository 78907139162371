import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import DirectionsCar from "@material-ui/icons/DirectionsCar";
import EmojiTransportation from "@material-ui/icons/EmojiTransportation";
import Filter5 from "@material-ui/icons/Filter5";
import LocalShipping from "@material-ui/icons/LocalShipping";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoAreaTwo from "components/InfoArea/InfoAreaTwo.jsx";

import productStyleTwo from "assets/jss/material-kit-react/views/landingPageSections/productStyletwo.jsx";

class ProductSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.sectionlocations}>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={6} md={3}>
              <InfoAreaTwo
                description="10 minute drive time to Junction 34 of the M4"
                icon={DirectionsCar}
                iconColor="warning"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <InfoAreaTwo
                description="Connected location to Cardiff, Swansea and the SW Valley conurbations"
                icon={EmojiTransportation}
                iconColor="warning"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <InfoAreaTwo
                description="Under 5 miles via A4119 to the M4
                "
                icon={Filter5}
                iconColor="warning"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <InfoAreaTwo
                description="New connecting road network providing a great distribution platform. 
                "
                icon={LocalShipping}
                iconColor="warning"
                vertical
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(productStyleTwo)(ProductSection);
