import React from 'react'
import GoogleMapReact from 'google-map-react'
import Marker from './Marker';

const defaultProps = {
  center: {
    lat: 51.55912,
    lng: -3.414641,
  },
  zoom: 15,
}

const getMapOptions = (maps: any) => {
  return {
    disableDefaultUI: false,
    mapTypeControl: true,
    streetViewControl: true,
    mapTypeId: maps.MapTypeId.HYBRID,
    styles: [{ featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'on' }] }],
  };
};

const handleApiLoaded = (map, maps) => {
  // use map and maps objects
};

const AnyReactComponent = ({ text }) => <div>{text}</div>

const GoogleMap = () => (
  <div style={{ height: '700px', width: '100%' }}>
    <GoogleMapReact
      bootstrapURLKeys={{ key: 'AIzaSyB819uCKUqQci8GUhR8e9YQQW321NCCXDg' }}
      defaultCenter={defaultProps.center}
      defaultZoom={defaultProps.zoom}
      options={getMapOptions}
      yesIWantToUseGoogleMapApiInternals
  onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
    >
      <AnyReactComponent
        lat={51.55912}
        lng={-3.414641}
        text={'Colliery Business Park'}
      />

        <Marker
            lat={51.55912}
            lng={-3.414641}
            name="Colliery Business Park"
            color="#0B292C"
          />
    </GoogleMapReact>
  </div>
)

export default GoogleMap