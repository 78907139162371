import {
    primaryColor,
    warningColor,
    dangerColor,
    successColor,
    infoColor,
    roseColor,
    grayColor,
    title
  } from "assets/jss/material-kit-react.jsx";
  
  const infoStyleTwo = {
    infoArea: {
      maxWidth: "360px",
      margin: "0 auto",
      padding: "20px"
    },
    iconWrapper: {
      float: "left",
      marginTop: "24px",
      marginRight: "10px"
    },
    primary: {
      color: primaryColor
    },
    warning: {
      color: warningColor
    },
    danger: {
      color: dangerColor
    },
    success: {
      color: successColor
    },
    info: {
      color: infoColor
    },
    rose: {
      color: roseColor
    },
    gray: {
      color: grayColor
    },
    icon: {
      width: "36px",
      height: "36px"
    },
    descriptionWrapper: {
      color: grayColor,
      overflow: "hidden"
    },
    title,
    description: {
      color: "#fff",
      overflow: "hidden",
      marginTop: "0px",
      fontSize: "12px",
      textTransform: "uppercase"
    },
    iconWrapperVertical: {
      float: "none"
    },
    iconVertical: {
      width: "45px",
      height: "45px"
    }
  };
  
  export default infoStyleTwo;