import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// React icons
import { FaPlay } from 'react-icons/fa';

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import GoogleMap from 'components/GoogleMap.tsx'
import SpecButton from "components/CustomButtons/SpecButton.jsx";
import locationimage from "../../assets/img/locations-map.png";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

// Sections for this page
import ProductSection from "./Sections/ProductSection.jsx";

const dashboardRoutes = [];

class LandingPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand="Material Kit React"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 400,
            color: "white"
          }}
          {...rest}
        />
        <Parallax filter image={require("assets/img/overheadheader.png")}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem>
              <div className={classes.brand}>
                <div className={classes.intro}>
                  a well established
                  </div>
                  <h1 className={classes.title}>Business location</h1>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classes.iconarea}>
          <div className={classes.border}></div>
        <ProductSection />
        </div>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>

          <div className={classes.locationstext}>
          Colliery Business Park is excellently located for businesses operating in the South Wales area, with excellent motorway connectivity accessing the main the business and residential areas of South Wales.  
<br/>
<br/>
This high quality industrial, urban logistics and warehousing development offer business in the delivery, supply chain and logistics sectors an unparalleled platform for commerce.
          </div>
          </div>
          <GoogleMap />

          <div className={classes.paddingone}></div>

          <div className={classes.mainwrap}>
          <GridContainer>

          <GridItem xs={12} sm={12} md={5}>
          <div className={classes.preheadinginverse}>
                 the perfect
                  </div>
                 
                <h1 className={classes.headinginverse}>Location</h1>
                <div className={classes.parawrapinverse}>
                <p className={classes.paratextinverse}>
                Colliery Business Park is located 1.5 miles north of Llantrisant and 7 miles north west of Cardiff.  The park is accessed off the A4119 which directly links the development to Junction 34 of the M4.

                </p>

                <p className={classes.paratextinverse}>
                The A4119 is arterial road and offers direct access along the route to amenities include a Tesco superstore, two retail parks, food and beverage outlets including McDonalds, Costa Coffee and Starbucks.
                </p>
    
                <p className={classes.paratextinverse}>
                Local business and older trade parks are home to notable occupiers including CAT Finning, TNT, The Royal Mint and Royal Mail.  
                </p>

                <br />
               
                <SpecButton/>
                <div className={classes.paddingonebottom}></div>
              </div>
                
            </GridItem>
            <GridItem xs={12} sm={12} md={7}>
          <div className={classes.imagewrap}>
          <img alt="..." src={locationimage} className={classes.homeimage} />
          </div>
          
            </GridItem>

          </GridContainer>
          </div>
          </div>
        
        <Footer />
      </div>
    );
  }
}

export default withStyles(landingPageStyle)(LandingPage);
